import { useQueryClient } from '@tanstack/react-query'
import { useEffect, useState } from 'react'
import cloneDeep from 'lodash.clonedeep'

import { auth } from '../../config/firebase'
import { SERVICE_LINES_ARRAY } from '../../constants/serviceLine'
import { states } from '../../constants/values'
import { toCamelCase } from '../../helpers/serializers'
import { toTherapist } from '../../helpers/transformers'
import type { CarePlan, Patient } from '../../types/Patient'
import type { ServiceLine } from '../../types/ServiceLine'
import type { State } from '../../types/State'
import type { Therapist } from '../../types/Therapist'
import { getIsAssessmentStatus } from '../../helpers/bookSessionStatusUtils'

interface GetProvidersProps {
  patients: Partial<Patient>[]
  ignoreTakingNewPrivatePatients?: boolean
  sortBy?: string
  withFirstAvailability?: boolean
  withServiceType?: boolean
  withLanguage?: boolean
  withLicenseJurisdiction?: boolean
  enabled?: boolean
  onlyServiceLines?: ServiceLine[]
  clientId?: string
}

export interface ResultGetProviders {
  patient: Partial<Patient>
  availableServiceLinesNames: string[]
  therapists: Therapist[]
}

export const fetchProviders = async (queryParams: string) => {
  const token: string = await auth.currentUser.getIdToken()

  return await fetch(
    `${import.meta.env.VITE_DOTCOM_BFF_URL}providers?${queryParams}`,
    {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    }
  ).then((response) => response.json())
}

const useGetProviders = ({
  clientId,
  patients,
  ignoreTakingNewPrivatePatients = false,
  sortBy = '',
  withFirstAvailability = true,
  withServiceType = true,
  withLanguage = true,
  withLicenseJurisdiction = true,
  enabled = true,
  onlyServiceLines = [],
}: GetProvidersProps) => {
  const queryClient = useQueryClient()
  const [result, setResult] = useState<ResultGetProviders[]>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [refetch, setRefetch] = useState<boolean>(false)

  useEffect(() => {
    if (!enabled || (!refetch && result?.length)) return

    setIsLoading(true)
    ;(async () => {
      const tempResult: ResultGetProviders[] = []

      for (
        let indexPatient = 0;
        indexPatient < patients?.length;
        indexPatient++
      ) {
        const patient = patients[indexPatient]

        const allServiceTypes =
          patient?.carePlans?.map(
            (cp: CarePlan) =>
              SERVICE_LINES_ARRAY.find(
                (sl: ServiceLine) => sl.displayName === cp.displayName
              ).serviceType
          ) || []

        const serviceTypes = onlyServiceLines?.length
          ? allServiceTypes.filter((serviceType: string) =>
              onlyServiceLines.some(
                (sl: ServiceLine) => sl.serviceType === serviceType
              )
            )
          : allServiceTypes

        const stateAbbrev = states.find(
          (s: State) => s?.name === patient?.state
        )?.abbrev

        const tempServiceTypesTherapists: Therapist[] = []

        for (let i = 0; i < serviceTypes?.length; i++) {
          const serviceType = serviceTypes[i]

          const isAssessment = getIsAssessmentStatus(
            patient,
            SERVICE_LINES_ARRAY.find(
              (sl: ServiceLine) => sl.serviceType === serviceType
            ).displayName
          )

          const queryParams = [
            ignoreTakingNewPrivatePatients
              ? ''
              : 'taking_new_private_patients=1',
            withServiceType ? `service_type=${serviceType}` : '',
            withServiceType ? `language=${patient?.preferredLanguage}` : '',
            withLicenseJurisdiction
              ? `license_jurisdiction=US-${stateAbbrev}`
              : '',
            sortBy ? `sort_by=${sortBy}` : '',
            `duration_in_minutes=${isAssessment ? 60 : 30}`,
            `state=${stateAbbrev}`,
            `client_id=${clientId}`,
          ]
          const queryParamsFiltered = queryParams.filter((qp) => qp)

          const queryKey = [
            'providers',
            withServiceType && serviceType,
            withLanguage && patient?.preferredLanguage,
            withLicenseJurisdiction && stateAbbrev,
            ignoreTakingNewPrivatePatients,
            sortBy,
          ].filter((e) => e)

          const cachedTherapists: Therapist[] =
            queryClient?.getQueryData(queryKey)

          if (cachedTherapists && !refetch) {
            const clonedCachedTherapists = cloneDeep(cachedTherapists)
            tempServiceTypesTherapists.push(...clonedCachedTherapists)
          } else {
            const providers: any = await fetchProviders(
              queryParamsFiltered.join('&')
            )

            const resultProviders: Therapist[] = providers?.map(
              (provider: any) => toTherapist(toCamelCase(provider))
            )

            const resultTherapists: Therapist[] = withFirstAvailability
              ? resultProviders.filter((p: Therapist) =>
                  Boolean(p.firstAvailability)
                )
              : resultProviders

            tempServiceTypesTherapists.push(...resultTherapists)
            queryClient?.setQueryData(queryKey, resultTherapists)
          }
        }

        // Filter out duplicate therapists based on their ID
        const uniqueTherapists = tempServiceTypesTherapists.filter(
          (therapist, index, self) =>
            index === self.findIndex((t) => t.id === therapist.id)
        )

        tempResult.push({
          patient,
          availableServiceLinesNames: Array.from(
            new Set(uniqueTherapists.map((t: Therapist) => t.serviceLine))
          ),
          therapists: uniqueTherapists.filter((t) =>
            t.licenseCredentials.includes(`US-${stateAbbrev}`)
          ),
        })
      }

      setRefetch(false)
      setResult(tempResult)
      setIsLoading(false)
    })()
  }, [enabled, refetch])

  return { data: result, isLoading, setRefetch }
}

export default useGetProviders
