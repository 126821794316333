import { capitalize } from '../helpers/generic'
import type { Therapist } from './Therapist'

export type UserWhoScheduled = {
  name: string
  id: string
}

export type Session = {
  patientId: string
  patientName: string
  type: string
  therapist: Therapist
  zoomLink: string
  startTime: string
  endTime: string
  status: string
  rating: number | null
  id: string
  carePlanId: string
  canceledAt: string
  name: string
  scheduledBy?: UserWhoScheduled
  sessionType?: string
}

export const mapSessionName = (
  therapyType: string,
  sessionType: string
): string => {
  return `${therapyType} ${
    sessionType
      ? capitalize(sessionType?.toLowerCase()?.replace('_', ' '))
      : 'Session'
  }`
}
