import { useMemo, type FC } from 'react'

import DashboardElement from '../DashboardElement'
import BookNewSessionBtn from '../../view-patient/tabs/BookNewSessionBtn'
import useHandleYesNowAllowedToBook from '../../../hooks/useHandleYesNowAllowedToBook'
import { enumeratedElementsFromArray } from '../../../helpers/generic'
import type { CarePlan, Patient } from '../../../types/Patient'
import { SERVICE_LINES_ARRAY } from '../../../constants/serviceLine'
import handleClickSupportEmail from '../../../helpers/supportClick'
import { useAuth } from '../../../contexts/AuthProvider'

type GROUPPED_PATIENTS_ITEM = {
  patient: Patient | Partial<Patient>
  serviceLines: string[]
}

type GROUPPED_PATIENTS = GROUPPED_PATIENTS_ITEM[]

const groupPatientsByIdForDisplay = (
  patients: (Patient | Partial<Patient>)[]
): GROUPPED_PATIENTS => {
  return Object.values(
    patients
      .filter((p) => p.isEligible)
      .reduce((acc: { [patientId: string]: GROUPPED_PATIENTS_ITEM }, p) => {
        const serviceLines =
          p?.carePlans
            ?.map(
              (cp: CarePlan) =>
                !cp.isIep &&
                SERVICE_LINES_ARRAY.find(
                  (s) => s.displayName === cp?.displayName
                )?.type
            )
            .filter(Boolean) || []

        return {
          ...acc,
          [p.id]: {
            patient: p,
            serviceLines,
          },
        }
      }, {})
  )
}

const YesAllowedToBook: FC = () => {
  const { user } = useAuth()
  const { patientsToShowNowAllowedMsgFor, handleDismissMsgNowAllowedPatients } =
    useHandleYesNowAllowedToBook()

  const groupedPatients = useMemo(
    () => groupPatientsByIdForDisplay(patientsToShowNowAllowedMsgFor),
    [patientsToShowNowAllowedMsgFor]
  )

  if (!patientsToShowNowAllowedMsgFor?.length || !groupedPatients?.length)
    return null

  return (
    <DashboardElement
      noGap
      title={
        <span>
          Additional care is now available for{' '}
          <span className="text-cta-default">
            {enumeratedElementsFromArray(
              patientsToShowNowAllowedMsgFor.map((p) =>
                p?.relationship?.key === 'myself' ? 'You' : p?.firstName
              )
            )}
          </span>
          !
        </span>
      }
    >
      <div className="flex flex-col gap-8">
        <p>
          Great news,{' '}
          {enumeratedElementsFromArray(
            groupedPatients?.map(
              (item) =>
                `${
                  item.patient?.relationship?.key === 'myself'
                    ? 'You'
                    : item.patient?.firstName
                } ${
                  item.patient?.relationship?.key === 'myself' ? 'are' : 'is'
                } now eligible to book ${enumeratedElementsFromArray(
                  item?.serviceLines
                )} therapy sessions`
            )
          )}
          !
          <br />
          Get started today! Questions? Contact{' '}
          <a
            href="mailto:support@huddleupcare.com"
            className="font-medium underline text-cta-default"
            onClick={() => handleClickSupportEmail(user, location)}
          >
            support@huddleupcare.com
          </a>
          .
        </p>
        <div className="flex flex-col gap-4">
          <BookNewSessionBtn
            onClickBookNewSession={handleDismissMsgNowAllowedPatients}
            withSpecificAvatars={patientsToShowNowAllowedMsgFor}
          />
          <button
            type="button"
            onClick={handleDismissMsgNowAllowedPatients}
            className="text-sm font-medium underline sm:text-base"
          >
            Dismiss
          </button>
        </div>
      </div>
    </DashboardElement>
  )
}

export default YesAllowedToBook
