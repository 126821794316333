import React, { useEffect, useState } from 'react'
import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/solid'
import { useLocation } from 'react-router-dom'

import BookNewSessionBtn from '../view-patient/tabs/BookNewSessionBtn'
import { useAuth } from '../../contexts/AuthProvider'
import type { Session } from '../../types/Session'
import type { CarePlan, Patient } from '../../types/Patient'
import { newDate } from '../../helpers/generic'
import SessionComponent, {
  SessionComponentLoader,
} from '../../components/SessionComponent'
import TabNavigation from '../../components/TabNavigation'
import { isValidPastSession } from '../../helpers/bookSessionStatusUtils'
import { Listbox } from '@headlessui/react'
import { usePatient } from '../../contexts/PatientProvider'
import useGetProviders from '../../queries/booking/UseGetProviders'
import useIsIEPOnly from '../../hooks/useIsIEPOnly'
import useMediaQuery from '../../hooks/useMediaQuery'
import trackMixPanel, { MIXPANEL_EVENT } from '../../hooks/useMixPanel'
import { getSourceFromLocation } from '../../helpers/utils'

const ITEMS_PER_PAGE = 10

const MyBookings: React.FC = () => {
  const location = useLocation()
  const isLargeEnough = useMediaQuery('(min-width: 640px)')
  const iepOnlyExperience = useIsIEPOnly()
  const { user } = useAuth()
  const { data: resultProviders, isLoading: isLoadingUseGetProviders } =
    useGetProviders({
      clientId: user?.data?.clientId,
      patients: user?.roster,
      enabled: Boolean(user?.roster?.length),
    })
  const { setPatient } = usePatient()
  const [activeTab, setActiveTab] = useState<string>('Upcoming')
  const [sessions, setSessions] = useState<Session[]>([])
  const [index, setIndex] = useState<number>(1)
  const [selectedPatients, setSelectedPatients] = useState<Partial<Patient>[]>(
    user.roster
  )

  useEffect(() => {
    setPatient(null)
  }, [])

  useEffect(() => {
    if (!user.roster?.length) return

    let sessions: Session[] = user?.roster
      ?.flatMap((patient: Patient) =>
        patient?.carePlans.flatMap((plan: CarePlan) =>
          plan.sessions.map((session: Session) => ({
            ...session,
            patient: {
              ...patient,
              timeZone: patient.timeZone,
            },
          }))
        )
      )
      ?.sort(
        (a, b) =>
          new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
      )

    sessions = sessions.filter((session: Session & { patient: Patient }) =>
      selectedPatients.some(
        (selectedPatient: Patient) => selectedPatient.id === session.patient.id
      )
    )

    if (activeTab === 'Upcoming')
      setSessions(
        sessions.filter(
          (session: Session & { patient: Patient }) =>
            session.status === 'Pending' &&
            newDate(new Date(session.startTime), session.patient.timeZone) >
              newDate(new Date(), session.patient.timeZone)
        )
      )
    else if (activeTab === 'Previous')
      setSessions(
        sessions.filter((session: Session & { patient: Patient }) =>
          isValidPastSession(session, session.patient)
        )
      )

    const selectedPatientsList = selectedPatients
      .map((f) => f.firstName)
      .join(',')

    trackMixPanel({
      eventName: MIXPANEL_EVENT.LIST_FILTERED,
      properties: {
        list: getSourceFromLocation(location),
        filterType: 'For Whom',
        filterValue: selectedPatientsList,
      },
    })
  }, [user, activeTab, selectedPatients])

  // scroll to top on page change
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [index])

  return (
    <div className="mb-auto flex w-full max-w-4.5xl flex-col items-start gap-28 pb-4 sm:px-4 lg:pt-10 xl:px-0 2xl:pt-18 xs:gap-6 xs:pt-24">
      {/* Header */}
      <div className="flex w-full items-center justify-between xs:flex-col xs:items-start xs:gap-6">
        <div className="flex flex-col gap-2">
          <p className="flex flex-row text-lg font-semibold sm:text-2xl">
            My Bookings
          </p>
          <p className="text-base font-normal xs:text-sm">
            See all the sessions provided by Huddle Up Therapists!
          </p>
        </div>
        {!isLoadingUseGetProviders &&
          resultProviders?.length > 0 &&
          !iepOnlyExperience && <BookNewSessionBtn tooltipPosition="left" />}
      </div>

      {/* Content */}
      <div className="flex w-full flex-col gap-10 xs:gap-6">
        <div className="flex items-center justify-between gap-4 lg:gap-0 xs:flex-wrap">
          <TabNavigation
            setActiveTab={setActiveTab}
            activeTab={activeTab}
            tabs={['Upcoming', 'Previous']}
            hiddenBottomBar={isLargeEnough}
          />

          {/* For Who */}
          <Listbox>
            <div className="relative my-auto">
              <Listbox.Button className="my-auto flex items-center gap-2">
                <p className="whitespace-nowrap text-xs font-semibold text-text-primary sm:text-sm">
                  For Whom
                </p>
                <p className="w-9 rounded-md bg-cta-default py-1 px-2.5 text-sm font-semibold text-white">
                  {selectedPatients?.length || 0}
                </p>
                <ChevronDownIcon className="h-5 w-5 text-text-label" />
              </Listbox.Button>

              <Listbox.Options className="absolute z-10 mt-2 max-h-96 min-w-min overflow-auto rounded-md bg-white p-4 shadow-lg ring-1 ring-cta-default ring-opacity-5 focus:outline-none sm:text-sm">
                {React.Children.toArray(
                  user.roster.map((patient: Patient) => (
                    <div className="my-2 flex items-center space-x-2">
                      <input
                        type="checkbox"
                        className="h-4 w-4 rounded border border-components-fields text-cta-default focus:ring-cta-default"
                        defaultChecked={selectedPatients.some(
                          (selectedPatient: Patient) =>
                            selectedPatient.id === patient.id
                        )}
                        onChange={(e) => {
                          if (e.target.checked) {
                            setSelectedPatients([...selectedPatients, patient])
                          } else {
                            setSelectedPatients(
                              selectedPatients.filter(
                                (selectedPatient: Patient) =>
                                  selectedPatient.id !== patient.id
                              )
                            )
                          }
                        }}
                      />
                      <span className="truncate">{patient.firstName}</span>
                    </div>
                  ))
                )}
              </Listbox.Options>
            </div>
          </Listbox>
        </div>

        {isLoadingUseGetProviders ? (
          React.Children.toArray(
            [...Array(4).keys()].map(() => (
              <SessionComponentLoader minimal={true} />
            ))
          )
        ) : (
          <>
            {!sessions?.length ? (
              <p className="text-base xs:text-sm">No sessions to show.</p>
            ) : (
              React.Children.toArray(
                sessions
                  .slice(index - 1, index + (ITEMS_PER_PAGE - 1))
                  .map((session: Session) => (
                    <div className="rounded-lg border border-components-fields bg-white p-6 shadow-subtle xs:p-4">
                      <SessionComponent
                        session={session}
                        showStatus={activeTab === 'Previous'}
                        showPatientNameWithStatus
                      />
                    </div>
                  ))
              )
            )}

            {/* Paginator */}
            <div className="flex items-center justify-between gap-8 xs:flex-col xs:gap-2">
              {/* Text */}
              <p className="text-sm">
                {sessions?.length <= ITEMS_PER_PAGE ? (
                  <>
                    Showing{' '}
                    <span className="font-semibold">{sessions?.length}</span> of{' '}
                    <span className="font-semibold">{sessions?.length}</span>{' '}
                    result
                    {sessions?.length > 1 && 's'}
                  </>
                ) : index + (ITEMS_PER_PAGE - 1) >= sessions?.length ? (
                  <>
                    Showing <span className="font-semibold">{index}</span> to{' '}
                    <span className="font-semibold">{sessions?.length}</span> of{' '}
                    <span className="font-semibold">{sessions?.length}</span>{' '}
                    results
                  </>
                ) : (
                  <>
                    Showing <span className="font-semibold">{index}</span> to{' '}
                    <span className="font-semibold">
                      {index + (ITEMS_PER_PAGE - 1)}
                    </span>{' '}
                    of <span className="font-semibold">{sessions?.length}</span>{' '}
                    results
                  </>
                )}
              </p>
              {/* Buttons */}
              <div className="flex items-center gap-8 xs:gap-6">
                <button
                  className="flex items-center text-sm font-semibold disabled:text-text-placeholder"
                  disabled={index === 1}
                  onClick={() =>
                    setIndex((current) => (current -= ITEMS_PER_PAGE))
                  }
                >
                  <ChevronLeftIcon className="h-5 w-5" /> Previous
                </button>
                <button
                  className="flex items-center text-sm font-semibold disabled:text-text-placeholder"
                  disabled={
                    sessions?.length <= ITEMS_PER_PAGE ||
                    index + (ITEMS_PER_PAGE - 1) >= sessions?.length
                  }
                  onClick={() =>
                    setIndex((current) => (current += ITEMS_PER_PAGE))
                  }
                >
                  Next
                  <ChevronRightIcon className="h-5 w-5" />
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default MyBookings
