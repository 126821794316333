import React, { useMemo } from 'react'
import type { PathMatch } from 'react-router-dom'
import { Navigate, useLocation, matchPath } from 'react-router-dom'

import { usePatient } from '../../contexts/PatientProvider'
import useCurrentServiceLine from '../../hooks/useCurrentServiceLine'
import type { CarePlan } from '../../types/Patient'
import { getIsAssessmentStatus } from '../../helpers/bookSessionStatusUtils'
import type { Session } from '../../types/Session'
import useHasUsedAllFreeSessions from '../../hooks/useHasUsedAllFreeSessions'

const RequireBookingLogic: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { patient } = usePatient()
  const location = useLocation()
  const usedAllFreeSessions = useHasUsedAllFreeSessions()
  const currentServiceLine = useCurrentServiceLine()
  const isAssessment: boolean = useMemo(
    () => getIsAssessmentStatus(patient, currentServiceLine?.displayName),
    [patient, currentServiceLine]
  )
  const carePlan: CarePlan = patient?.carePlans?.find(
    (cp: CarePlan) =>
      !cp.isIep && cp.displayName === currentServiceLine.displayName
  )
  const haveCarePlanPendingSessions: boolean = carePlan?.sessions.some(
    (s: Session) => s.status === 'Pending'
  )

  if (patient?.isIepOnly || patient?.isDisabled)
    return <Navigate to="/dashboard" />

  // plan has bulk model
  // ran out of bulk model sessions
  // did not confirm understanding of bulk model
  if (
    [
      matchPath(`/booking/:serviceLine`, location.pathname),
      matchPath(`/booking/:serviceLine/providers`, location.pathname),
      matchPath(
        `/booking/:serviceLine/providers/:providerId`,
        location.pathname
      ),
    ].some((pm: PathMatch) => Boolean(pm)) &&
    Boolean(carePlan.bulkModel) &&
    carePlan.bulkModel.isBulkModel &&
    !carePlan.bulkModel.remainingSessionsOnBulkModel
  )
    return (
      <Navigate
        to={`/booking/${currentServiceLine.url}/out-of-bulk-sessions`}
      />
    )

  if (
    isAssessment &&
    haveCarePlanPendingSessions &&
    !usedAllFreeSessions &&
    !location.pathname.includes('already-booked') &&
    !location.pathname.includes('confirmation') &&
    !location.pathname.includes('success')
  )
    return <Navigate to={`/booking/${currentServiceLine.url}/already-booked`} />

  // if
  // used all free sessions
  // and
  // is not allowed to book more
  if (
    usedAllFreeSessions &&
    !location.pathname.includes('maxed-out') &&
    !location.pathname.includes('confirmation') &&
    !location.pathname.includes('success')
  )
    return <Navigate to={`/booking/${currentServiceLine.url}/maxed-out`} />

  return <>{children}</>
}

export default RequireBookingLogic
