import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/solid'
import React, { Fragment, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { flushSync } from 'react-dom'

import ProfileTab from './tabs/ProfileTab'
import SessionsTab from './tabs/SessionsTab'
import useMediaQuery from '../../hooks/useMediaQuery'
import type { CarePlan } from '../../types/Patient'
import DocumentsTab from './tabs/DocumentsTab'
import { usePatient } from '../../contexts/PatientProvider'
import TopicsTab from './tabs/TopicsTab'
import { uniqueCarePlans } from '../../helpers/utils'
import NotAllowedToBookAnymoreSessions from './NotAllowedToBookAnymoreSessions'
import trackMixPanel, { MIXPANEL_EVENT } from '../../hooks/useMixPanel'
import getAvatarSrc from '../../helpers/getAvatarSrc'
import { useAuth } from '../../contexts/AuthProvider'

type Tab = {
  name: string
  component: React.ReactNode
}

type TabMap = {
  [key: string]: Tab
}

const tabs = (): TabMap => ({
  Sessions: {
    name: 'Sessions',
    component: <SessionsTab />,
  },
  Profile: {
    name: 'Profile',
    component: <ProfileTab />,
  },
  Topics: {
    name: 'Topics of Interest',
    component: <TopicsTab />,
  },
  Documents: {
    name: 'Documents',
    component: <DocumentsTab />,
  },
})

const ViewPatientScreen: React.FC = () => {
  const navigate = useNavigate()
  const { user } = useAuth()
  const { patient, setPatient } = usePatient()
  const isLargeEnough = useMediaQuery('(min-width: 640px)')
  const tabsWithProps = tabs()

  const [activeTab, setActiveTab] = useState<Tab>(null)

  const switchTab = (tab: any) => {
    if (!isLargeEnough && activeTab?.name === tab.name) {
      setActiveTab(null)
      return
    }

    setActiveTab(tab)
  }

  const goBack = () => {
    if (!isLargeEnough && activeTab) setActiveTab(null)
    else {
      flushSync(() => {
        setPatient(null)
        navigate('/dashboard')
      })
    }
  }

  // set active tab from url Hash
  useEffect(() => {
    const tab = tabsWithProps[window.location.hash.substring(1)]
    setActiveTab(
      tab || (isLargeEnough ? Object.values(tabsWithProps)[0] : null)
    )
  }, [isLargeEnough])

  useEffect(() => {
    if (!activeTab) return

    trackMixPanel({
      eventName: MIXPANEL_EVENT.ROSTER_PROFILE_VIEWED,
      properties: {
        accountId: user.data.id,
        page: activeTab.name,
      },
    })
  }, [activeTab])

  return (
    <div className="flex w-full flex-col sm:max-w-2xl sm:items-center sm:px-2 sm:py-20 md:max-w-3xl md:px-2 lg:max-w-4xl lg:px-0 xl:max-w-5xl xs:pb-5">
      {/* Back button */}
      <div className="mb-6 flex w-full items-start text-base font-semibold text-cta-default underline sm:ml-0 xs:ml-4">
        {isLargeEnough || !activeTab ? (
          <button onClick={goBack} className="flex items-center">
            <ChevronLeftIcon className="h-5 w-5" />
            Back
          </button>
        ) : (
          <button className="flex items-center" onClick={goBack}>
            <ChevronLeftIcon className="h-5 w-5" />
            Back
          </button>
        )}
      </div>

      {/* Patient content */}
      <div className="flex w-full flex-col rounded-2xl bg-white sm:border-2 sm:border-cta-disabled sm:py-8 sm:px-10 xs:gap-8 xs:p-4">
        {/* Header */}
        <div
          className={`${
            Boolean(activeTab) ? 'xs:hidden' : ''
          } flex w-full items-center justify-center`}
        >
          {/* Image */}
          <img
            src={getAvatarSrc(user, patient)}
            className="h-20 w-20 sm:h-32 sm:w-32"
            alt="patient-avatar"
          />

          {/* Info */}
          <div className="flex flex-col gap-4 sm:ml-10 xs:ml-6">
            <p className="font-semibold text-text-primary sm:text-2xl xs:text-base">
              {patient.firstName} {patient.lastName}{' '}
              <span className="font-semibold text-text-label">
                {patient.age} yo
              </span>
            </p>
            <div className="flex flex-wrap gap-2">
              {React.Children.toArray(
                uniqueCarePlans(patient?.carePlans)?.map((cp: CarePlan) => (
                  <div className="rounded-lg border border-components-fields sm:py-2 sm:px-4 xs:py-1 xs:px-2">
                    <p className="text-sm font-semibold text-text-secondary">
                      {cp.displayName}
                    </p>
                  </div>
                ))
              )}
            </div>
          </div>
        </div>

        {/* Divider */}
        <div className={`${activeTab ? 'xs:hidden' : ''} relative sm:hidden`}>
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-300" />
          </div>
        </div>

        {!isLargeEnough && <NotAllowedToBookAnymoreSessions />}

        {/* Tab navigation */}
        <div className="flex w-full justify-center sm:my-12 sm:border-b sm:border-b-components-fields xs:flex-col">
          {React.Children.toArray(
            Object.keys(tabsWithProps).map((key) => (
              <Fragment>
                <button
                  onClick={() => switchTab(tabsWithProps[key])}
                  className={`text-base font-semibold last:mb-0 sm:mx-2 sm:-mb-px sm:px-2 sm:pb-1 md:mx-3 md:px-5 xs:mb-4 xs:rounded-lg xs:border xs:border-components-fields xs:p-4 xs:text-left xs:text-text-primary
                ${
                  activeTab?.name === tabsWithProps[key].name
                    ? 'sm:border-b-2 sm:border-b-cta-disabled sm:text-cta-default'
                    : 'sm:text-text-label '
                }
                ${Boolean(activeTab) ? 'xs:hidden' : ''}
                `}
                >
                  <div className="flex w-full justify-center xs:justify-between">
                    <p>{tabsWithProps[key].name}</p>
                    {activeTab?.name === tabsWithProps[key].name ? (
                      <ChevronDownIcon className="h-6 w-5 sm:hidden" />
                    ) : (
                      <ChevronRightIcon className="h-6 w-5 sm:hidden" />
                    )}
                  </div>
                </button>

                {activeTab?.name === tabsWithProps[key].name && (
                  <div className="sm:hidden">{activeTab?.component}</div>
                )}
              </Fragment>
            ))
          )}
        </div>

        {isLargeEnough && <NotAllowedToBookAnymoreSessions />}

        {/* Tab content for bigger screens */}
        <div className="xs:hidden">{activeTab?.component}</div>
      </div>
    </div>
  )
}

export default ViewPatientScreen
