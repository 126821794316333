import React, { useEffect, useState } from 'react'
import { Navigate, useNavigate } from 'react-router-dom'
import { formatInTimeZone } from 'date-fns-tz'

import type { ServiceLine } from '../../types/ServiceLine'
import {
  secondaryButtonClass,
  tertiaryButtonClassSmall,
} from '../../constants/classConstants'
import { SERVICE_LINES_ARRAY } from '../../constants/serviceLine'
import useCurrentServiceLine from '../../hooks/useCurrentServiceLine'
import SPEAK_LANGUAGE from '../../assets/icons/speakLanguage.svg'
import CALENDAR from '../../assets/icons/Calendar.svg'
import type { Therapist } from '../../types/Therapist'
import { LICENSE_CREDENTIAL } from '../../constants/values'
import SkipAndComeBackLater from '../../components/SkipAndComeBackLater'
import type { CarePlan, Patient } from '../../types/Patient'
import { usePatient } from '../../contexts/PatientProvider'
import { useProviderContext } from '../../contexts/ProviderContext'
import { useAuth } from '../../contexts/AuthProvider'
import useGetProviders from '../../queries/booking/UseGetProviders'

const DEFAULT_MAX_MATCHES_SLICE = 6

const Providers: React.FC = (): JSX.Element => {
  const { user } = useAuth()
  const navigate = useNavigate()
  const { patient } = usePatient()
  const { wantDifferentTherapist } = useProviderContext()
  const patientCarePlans: CarePlan[] = patient?.carePlans?.filter(
    (cp) => !cp.isIep
  )
  const currentServiceLine: ServiceLine = useCurrentServiceLine()
  const forSelf: boolean = patient?.relationship?.name === 'Myself'

  const { data: resultProviders, isLoading: isLoadingTherapists } =
    useGetProviders({
      clientId: user?.data?.clientId,
      patients: [patient],
      onlyServiceLines: [currentServiceLine],
      withFirstAvailability: true,
      ignoreTakingNewPrivatePatients: true,
    })

  const therapists = resultProviders?.find(
    (rp) => rp.patient.id === patient?.id
  )?.therapists

  const [currentSlice, setCurrentSlice] = useState<number>(
    DEFAULT_MAX_MATCHES_SLICE
  )
  const [loadingMore, setLoadingMore] = useState<boolean>(false)
  const computedMatches = React.useMemo((): Therapist[] => {
    if (!therapists) return []

    if (wantDifferentTherapist)
      return therapists.filter(
        (t) =>
          t.id !==
          patientCarePlans?.find(
            (cp: CarePlan) => cp.displayName === currentServiceLine.displayName
          )?.providerId
      )

    return [...therapists]
  }, [therapists, patient.preferredLanguage])

  const handleLoadMore = async (): Promise<void> => {
    setLoadingMore(true)
    setTimeout(() => {
      setCurrentSlice(
        (currentSliceNow) => currentSliceNow + DEFAULT_MAX_MATCHES_SLICE
      )
      setLoadingMore(false)
    }, 1000)
  }

  useEffect(() => {
    if (computedMatches?.length === 1)
      navigate(`${computedMatches[0].id}`, {
        replace: true,
        state: {
          from: `/booking/${currentServiceLine.url}`,
        },
      })
  }, [computedMatches])

  if (!patient) return <Navigate to="/dashboard" />

  return (
    <div className="max-w-5xl text-center">
      {!isLoadingTherapists && (
        <div className="flex flex-col items-center gap-6">
          {wantDifferentTherapist ? (
            <p className="text-base font-semibold sm:text-2xl">
              Book with a different {currentServiceLine.type} therapist
              {!forSelf && (
                <>
                  {' '}
                  for{' '}
                  <span className="text-cta-default">{patient?.firstName}</span>
                </>
              )}
            </p>
          ) : (
            <p className="text-base font-semibold sm:text-2xl">
              <span>Here {therapists?.length !== 1 ? 'are' : 'is'} the</span>{' '}
              <span className="text-primaryAccents-orange">
                {therapists?.length}
              </span>{' '}
              <span>
                {currentServiceLine.type} therapist
                {therapists?.length !== 1 ? 's' : ''} that would love to meet
              </span>{' '}
              <span className={`${forSelf ? '' : 'text-cta-default'}`}>
                {forSelf ? 'you' : patient?.firstName}
              </span>
            </p>
          )}
          <p className="text-sm font-normal sm:text-base">
            Click view more to see their information
            {wantDifferentTherapist && (
              <>
                <br />
                Your therapist will not update until you book a session with the
                new therapist.
              </>
            )}
          </p>
        </div>
      )}
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 sm:gap-10 lg:grid-cols-2 xl:grid-cols-3">
        {!isLoadingTherapists &&
          React.Children.toArray(
            computedMatches
              .slice(0, currentSlice)
              .map((t: Therapist) => (
                <ProviderCard therapist={t} patient={patient} />
              ))
          )}
        {(isLoadingTherapists || loadingMore) &&
          React.Children.toArray(
            [...Array(isLoadingTherapists ? 6 : 3).keys()].map(() => (
              <ProviderCardSkeleton />
            ))
          )}
      </div>
      {therapists?.length > DEFAULT_MAX_MATCHES_SLICE &&
        currentSlice < therapists?.length &&
        !loadingMore && (
          <button
            className={`${secondaryButtonClass} self-center !leading-5`}
            onClick={handleLoadMore}
          >
            Load more (
            {Math.min(
              DEFAULT_MAX_MATCHES_SLICE,
              therapists?.length - currentSlice
            )}
            )
          </button>
        )}
      {!isLoadingTherapists && !loadingMore && <SkipAndComeBackLater />}
    </div>
  )
}

export const ProviderCard: React.FC<{
  therapist: Therapist
  patient: Partial<Patient>
}> = ({ therapist, patient }) => {
  const navigate = useNavigate()

  const handleClick = () => {
    navigate(`${therapist.id}`, {
      state: {
        provider: therapist,
      },
    })
  }

  return (
    <div className="flex flex-col justify-between space-y-4 rounded-lg border border-components-fields bg-white p-6 text-left text-text-primary">
      <div className="flex flex-row items-start space-x-6 text-text-primary">
        <img
          className="h-14 w-14 rounded-full object-cover sm:h-20 sm:w-20"
          src={therapist.src}
          alt="Therapist"
        />
        <div className="flex flex-col items-start space-y-1 self-center sm:space-y-2">
          <h3 className="text-sm font-semibold sm:text-lg">
            {therapist.preferredName}
          </h3>
          <p className="text-label text-left text-xs font-semibold sm:text-base">
            {therapist?.licenseCredential ||
              LICENSE_CREDENTIAL[
                SERVICE_LINES_ARRAY.find(
                  (line: ServiceLine) =>
                    line.displayName === therapist.serviceLine
                ).serviceType
              ]}
          </p>
        </div>
      </div>
      <div className="hidden flex-row items-start sm:flex">
        <img src={SPEAK_LANGUAGE} alt="language" className="mr-0.5" />
        <p className="text-sm font-normal">
          {React.Children.toArray(
            therapist.languages.map((language, i) => (
              <span>
                {language}
                {i < therapist.languages?.length - 1 && ', '}
              </span>
            ))
          )}
        </p>
      </div>
      <div className="flex-1">
        <p className="text-base line-clamp-3 xs:text-sm">
          {therapist.description}
        </p>
      </div>
      <div className="flex flex-col space-y-1">
        <p className="text-sm font-semibold sm:text-base">First Availability</p>
        <div className="flex flex-row">
          <img src={CALENDAR} alt="language" className="mr-2" />
          <p className="text-sm font-normal sm:text-base">
            {therapist.firstAvailability
              ? formatInTimeZone(
                  therapist.firstAvailability,
                  patient.timeZone,
                  'MMMM d, yyyy h:mmaaa'
                )
              : 'None.'}
          </p>
        </div>
      </div>
      <button
        onClick={handleClick}
        className={`${tertiaryButtonClassSmall} w-full`}
      >
        View More
      </button>
    </div>
  )
}

const ProviderCardSkeleton: React.FC = () => {
  return (
    <div className="flex animate-pulse flex-col space-y-4 rounded-lg border border-components-fields bg-white p-6">
      <div className="flex flex-row items-center space-x-6">
        <div className="h-14 w-14 rounded-full bg-components-fields sm:h-20 sm:w-20" />
        <div className="flex flex-1 flex-col space-y-1 sm:space-y-2">
          <div className="h-3.5 rounded-full bg-components-fields sm:h-5" />
          <div className="h-3 rounded-full bg-components-fields sm:h-4" />
        </div>
      </div>
      <div className="hidden h-3.5 w-5/12 rounded-full bg-components-fields sm:block" />
      <div className="flex-1 flex-col space-y-2 sm:flex">
        <div className="h-3.5 rounded-full bg-components-fields sm:h-4" />
        <div className="h-3.5 rounded-full bg-components-fields sm:h-4" />
        <div className="h-3.5 rounded-full bg-components-fields sm:h-4" />
        <div className="hidden h-3.5 rounded-full bg-components-fields sm:block sm:h-4" />
      </div>
      <div className="flex flex-col space-y-2">
        <div className="h-3.5 w-32 rounded-full bg-components-fields sm:h-4 sm:w-36" />
        <div className="h-3.5 w-48 rounded-full bg-components-fields sm:h-4 sm:w-56" />
      </div>
      <div className="h-11 w-full rounded-md bg-components-fields" />
    </div>
  )
}

export default Providers
