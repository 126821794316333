import type { Location as ReactRouterDomLocation } from 'react-router-dom'

import type { CarePlan, Patient } from '../types/Patient'

export const imageCache = new Map()

export const secureRandomNumber = (min: number, max: number): number => {
  const range = max - min
  const randomArray = new Uint32Array(1)
  window.crypto.getRandomValues(randomArray)
  return min + (randomArray[0] % range)
}

export const paginationToken: number = secureRandomNumber(1, 99)

export const uniqueCarePlans = (inputArray: CarePlan[]): CarePlan[] => {
  return inputArray?.reduce((accumulator, currentObject) => {
    const exists = accumulator.some(
      (obj: CarePlan) => obj.displayName === currentObject.displayName
    )

    // If it doesn't exist, add the object to the accumulator
    if (!exists) {
      accumulator.push(currentObject)
    }

    return accumulator
  }, [])
}

export function formatModuleDuration(seconds: number): string {
  const minutes = Math.floor(seconds / 60)
  const remainingSeconds = seconds % 60
  return `${minutes.toString().padStart(2, '0')}:${remainingSeconds
    .toString()
    .padStart(2, '0')}`
}

export const convertSecondsToHumanReadable = (seconds: number): string => {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds % 3600) / 60)
  const remainingSeconds = seconds % 60

  const hoursStr = hours > 0 ? `${hours}h` : ''
  const minutesStr = minutes > 0 ? `${minutes}m` : ''
  const secondsStr = remainingSeconds > 0 ? `${remainingSeconds}s` : ''

  return [hoursStr, minutesStr, secondsStr].join(' ').trim() || '0s'
}

export function parseJwt(token: string) {
  const base64Url = token.split('.')[1]
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split('')
      .map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join('')
  )

  return JSON.parse(jsonPayload)
}

export const getPatientType = (patient: Partial<Patient>) => {
  switch (true) {
    case patient.isIep && patient.isEligible:
      return 'Both'
    case patient.isEligible:
      return 'Gen Ed'
    case patient.isIep:
      return 'Iep Only'
    default:
      return 'Disabled'
  }
}

export const getSourceFromLocation = (
  location: Location | ReactRouterDomLocation
) => {
  const lowerLocation = location.pathname.toLowerCase()
  const lowerLocationHash = location.hash.toLowerCase()
  switch (true) {
    case lowerLocation.includes('bookings') &&
      lowerLocationHash.includes('upcoming'):
      return 'Upcoming Bookings'
    case lowerLocation.includes('bookings') &&
      lowerLocationHash.includes('previous'):
      return 'Previous Bookings'
    case lowerLocation.includes('resources') &&
      lowerLocationHash.includes('multimedia'):
      return 'Multimedia'
    case lowerLocation.includes('bookmarks') &&
      lowerLocationHash.includes('multimedia'):
      return 'Bookmarked Multimedia'
    case lowerLocation.includes('resources') &&
      lowerLocationHash.includes('courses'):
      return 'Courses'
    case lowerLocation.includes('bookmarks') &&
      lowerLocationHash.includes('courses'):
      return 'Bookmarked Courses'
    case lowerLocation.includes('dashboard'):
      return 'Homepage'
    case lowerLocation.includes('resources'):
      return 'Resources'
    case lowerLocation.includes('booking'):
      return 'Booking'
    case lowerLocation.includes('contact'):
      return 'Contact Us'
    case lowerLocation.includes('patient'):
      return 'Profile'
    default:
      return 'Homepage'
  }
}

export const getTaskTypeFromUrl = (url: string) => {
  const lowerLocation = url.toLowerCase()
  switch (true) {
    case lowerLocation.includes('contact'):
      return 'Emergency Contact'
    case lowerLocation.includes('address'):
      return 'Physical Address'
    case lowerLocation.includes('check-in'):
      return 'Check-in'
    default:
      return ''
  }
}
