import React, { useEffect, useState } from 'react'

import DashboardElement from '../DashboardElement'
import { useAuth } from '../../../contexts/AuthProvider'
import type { Patient } from '../../../types/Patient'
import { enumeratedElementsFromArray } from '../../../helpers/generic'
import handleClickSupportEmail from '../../../helpers/supportClick'

const NotAllowedToBookAnymore: React.FC = () => {
  const { user } = useAuth()
  const [notAllowedToBookPatients, setNotAllowedToBookPatients] = useState([])

  const allowedToBookHistories = user.allowedToBookHistories

  useEffect(() => {
    if (!user?.roster?.length) return

    const { patientsNotAllowedToBookNow } = user.roster.reduce(
      (
        acc: {
          patientsNotAllowedToBookNow: (Patient | Partial<Patient>)[]
        },
        p: Patient | Partial<Patient>
      ) => {
        if (
          (!p.allowedToBook &&
            allowedToBookHistories[p.id]?.[0]?.changedTo === false &&
            allowedToBookHistories[p.id]?.[1]?.changedTo === true) ||
          (!p.isEligible && !p.isIep)
        )
          acc.patientsNotAllowedToBookNow.push(p)

        return acc
      },
      {
        patientsNotAllowedToBookNow: [],
      }
    )

    setNotAllowedToBookPatients(patientsNotAllowedToBookNow)
  }, [user, allowedToBookHistories])

  if (!notAllowedToBookPatients?.length) return null

  return (
    <DashboardElement
      noGap
      title={
        <span>
          You cannot schedule any sessions for{' '}
          <span className="text-cta-default">
            {enumeratedElementsFromArray(
              notAllowedToBookPatients.map((p: Patient) =>
                p?.relationship?.key === 'myself' ? 'yourself' : p.firstName
              )
            )}
          </span>{' '}
          at this time.
        </span>
      }
    >
      <p className="text-base xs:text-sm">
        Please contact the school or sponsoring organization on how services can
        be scheduled. For any other questions, please contact{' '}
        <a
          href="mailto:support@huddleupcare.com"
          className="font-medium underline text-cta-default"
          onClick={() => handleClickSupportEmail(user, location)}
        >
          support@huddleupcare.com
        </a>
        .
      </p>
    </DashboardElement>
  )
}

export default NotAllowedToBookAnymore
