import { useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom'

import { primaryButtonClass } from '../../constants/classConstants'
import { useAuth } from '../../contexts/AuthProvider'
import useCurrentServiceLine from '../../hooks/useCurrentServiceLine'
import handleClickSupportEmail from '../../helpers/supportClick'
import useSeenCOSPage from '../../hooks/useSeenCOSPage'

const BulkModelOutOfFreeSessions = () => {
  const navigate = useNavigate()
  const { markCOSPageAsSeen } = useSeenCOSPage()
  const serviceLine = useCurrentServiceLine()
  const { user } = useAuth()
  const clientInfo = user.data.clientData

  const handleContinueBooking = () =>
    navigate(`/booking/${serviceLine.url}/providers`)

  // mark in local storage that user seen the COS page
  useEffect(() => {
    markCOSPageAsSeen()
  }, [])

  const schoolOrOrganization =
    clientInfo?.clientType === 'EDUCATION' ? 'school' : 'organization'

  return (
    <div className="max-w-2xl items-center text-center">
      <div className="flex flex-col items-center gap-6">
        <h1 className="text-2xl font-semibold xs:text-lg">
          Information about therapy payment options
        </h1>
        <p className="max-w-xl whitespace-pre-wrap text-base xs:text-sm">
          Looks like your {schoolOrOrganization} has run out of covered
          sessions, and any future sessions will need to be paid out of pocket.
          For more information, please reach out to your {schoolOrOrganization}.
          <br />
          <br />
          If you have any questions or concerns about payment options, please
          don't hesitate to contact us at{' '}
          <a
            href="mailto:support@huddleupcare.com"
            className="font-medium text-cta-default underline xs:text-sm"
            onClick={() => handleClickSupportEmail(user, location)}
          >
            support@huddleupcare.com
          </a>
          .
        </p>
      </div>
      <div className="flex w-full max-w-lg flex-col gap-6 sm:gap-12">
        <button
          type="button"
          onClick={() => handleContinueBooking()}
          className={`${primaryButtonClass} mx-auto w-full`}
        >
          I understand, continue booking
        </button>
        <Link
          to="/dashboard"
          className="flex self-center text-sm font-medium text-text-secondary underline sm:text-base"
        >
          Go to my dashboard
        </Link>
      </div>
    </div>
  )
}

export default BulkModelOutOfFreeSessions
